import { Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Home from '../pages/home';
import React, { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react"
import { loginRequest } from "../config/autoconfig";
import { getForms } from '../actions/emission_action';
import { ACCOUNTS, AUTH, BASE_URL, FORMS, GRAPH_BASE_URL, LOGIN, USERS } from "../constant/apiEndpoints";
import LoadingHome from "../pages/loadingHome"

const Routes = (props) => {
    const dispatch = useDispatch()
    //MSAL Library Hooks
    const { instance, accounts } = useMsal();

    //Component States Hooks
    const [azAccessToken, setAzAccessToken] = useState(null); //Azure Access Token
    const [jwtToken, setJWTToken] = useState(null); //JWT Access Token
    const [isJWTAuthenticated, setIsJWTAutenticated] = useState(false); //JWT Authentication Flag
    const [formList, setFormList] = React.useState([])
    const [loading, setLoading] = useState(true);
    const formData = useSelector((state) => state.emissions.formList)

    const loadFormList = async (token) => {
        let url = BASE_URL + ACCOUNTS + USERS + FORMS
        await fetch(url, {
            method: 'GET',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            }
        })
            .then((json) => {
                //Storing  User Data And Token In Local Storage
                setFormList(json);
                return json;
            })
            .catch((e) => {
                console.log('error in user login API', e)
            })
    }

    const performJWTAuthentication = async (token, userGroups) => {
        localStorage.setItem('userList', JSON.stringify(userGroups))
        const body = {
            "first_name": accounts[0].name.split(" ")[0],
            "last_name": accounts[0].name.split(" ")[1],
            "email": accounts[0].username,
            "ad_group_ids": userGroups,
        }
        let url = BASE_URL + AUTH + USERS + LOGIN
        await fetch(url, {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)

        }).then((response) => {
            if (response.status === 200) {
                setIsJWTAutenticated(true);
                return response.json();
            }
        })
            .then((json) => {
                //Storing  User Data And Token In Local Storage
                if ('access' in json) {
                    console.log('res')
                    localStorage.setItem('access_token',
                        JSON.stringify(json))
                    loadFormList(json.access)
                    setJWTToken(json.access)
                    setLoading(false)
                }
                return json;
            })
            .catch((e) => {
                console.log('error in user login API', e)
            })
    }

    const getUserGroups = async (token) => {
        let url = GRAPH_BASE_URL + USERS + `${accounts[0].localAccountId}/getMemberGroups`
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify({ securityEnabledOnly: true })
            }).then((response) => response.json())
            .then((json) => {
                performJWTAuthentication(token, json.value)
            })
            .catch((e) => {
                console.log('error in graph user groups API', e)
            })
    }

    function RequestAccessToken() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAzAccessToken(response.accessToken); //Setting azAccessToken
            getUserGroups(response.accessToken); //
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                setAzAccessToken(response.accessToken);//Setting azAccessToken
                getUserGroups(response.accessToken)
            });
        });
    }

    useEffect(() => {
        if (localStorage.getItem('access_token') && localStorage.getItem('access_token')?.length > 0) {
            dispatch(getForms())
        }
    }, [jwtToken])

    useEffect(() => {
        let mounted = true
        if (mounted) {
            RequestAccessToken();
        }
        return function cleanup() {
            mounted = false
        }
    }, []);

    return (
        <Switch>
            {loading ? <LoadingHome /> :
                <Route path="/" component={() => <Home forms={formList} steward={formData.length > 0} token={jwtToken} />} />
            }

        </Switch>
    );
};

export default Routes;